import React, { useEffect, useState } from 'react'
import RedpocketLayout from '../../components/RedpocketLayout'
import { PageModelTitle } from '../../model/PageModel'
import { useIntl } from 'gatsby-plugin-intl-v6'
import { postNewtonApi } from '../../utils/axiosData'
import { newtonApiUrl, redpocketRedirect } from '../../utils/url'
import logo from "../../static/images/redpocket/logo.png"
import defaultAvator from "../../static/images/redpocket/default_avator.png"
import openZh from "../../static/images/redpocket/btn_open_nor_zh.png"
import openEn from "../../static/images/redpocket/btn_open_nor.png"
import slogan from "../../static/images/redpocket/word_slogan.png"
import maskZh from "../../static/images/redpocket/mask-zh.png"
import maskEn from "../../static/images/redpocket/mask-en.png"

export default RedpocketLoading

function RedpocketLoading() {
  const intl = useIntl()
  let pageModel = new PageModelTitle(intl.formatMessage({ id: 'Redeem RedPocket' }), 'redpocket')
  return <div>{RedpocketLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const isZh = intl.locale === 'zh'

  const [giverName, setGiverName]: any = useState('')
  const [giverAvatar, setGiverAvatar]: any = useState('')
  const [message, setMessage]: any = useState('')
  const [wechat, setWechat]: any = useState(false)
  const [redirectScheme, setRedirectScheme]: any = useState('')

  useEffect(() => {
    if (typeof window === "undefined") return;
    const searchParams = new URLSearchParams(window.location.search)
    let pocket_id = searchParams.get('id')
    if (pocket_id == null) {
      pocket_id = ''
    }
    const redirect_scheme = redpocketRedirect + pocket_id
    const download_url = '/redpocket/download/?id=' + pocket_id
    setRedirectScheme(redirect_scheme)

    const isMobile = () => {
      try {
        return /Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(
          navigator.userAgent
        );
      } catch (e) {
        console.log("Error in isMobile");
        return false;
      }
    };
    const checkDirect = (scheme: any, download_url: any) => {
      if (!isMobile()) {
        window.location = download_url;
        return;
      } else {
        console.log("isMobile");
      }

      const now = new Date().valueOf();
      setTimeout(() => {
        if (new Date().valueOf() - now > 100) return;
        window.location = download_url;
      }, 25);
      window.location = scheme;
    };

    if (/MicroMessenger/i.test(navigator.userAgent)) {
      setWechat(true)
    } else {
      checkDirect(redirect_scheme, download_url);
    }
  
    const dataUrl = newtonApiUrl + 'dapp/redpocket/public/'
    const fetchData = async () => {
      const params = new URLSearchParams()
      params.append("pocket_id", pocket_id)
      const res = await postNewtonApi(dataUrl, params)
      console.log('fetchData res:', res)
      if (res && res.data && res.data.result) {
        setGiverName(res.data.result.giver_name)
        setGiverAvatar(res.data.result.giver_avatar)
        setMessage(res.data.result.pocket_message)
      }
    }
    fetchData()
  }, [redpocketRedirect])

  return (
    <>
      <div className="main">
        <div className="row">
          <span className="pull-left">
            <img src={logo} alt='logo' className="logo" />
          </span>
        </div>
        <div className="row redirect-content">
          <div className="profile">
            <p className="text-center head">
                {giverAvatar ? <img src={ giverAvatar } alt='avatar' /> : 
                <img src={defaultAvator} alt='avatar' />}
            </p>
            <p className="text-center name">{ giverName }</p>
            <p className="text-center message">{ message }</p>
          </div>
          <p className="text-center">
              <a href={ redirectScheme }>
              {isZh ? <img src={openZh} className="open" /> : <img src={openEn} className="open" />}
              </a>
          </p>
        </div>
        <div className="row redirect-footer">
          <p className="text-center slogan">
            <img src={slogan} alt='slogan' />
          </p>
        </div>
      </div>
      { wechat &&
      <div className="mask">
        {isZh ? <img src={maskZh} alt='mask' /> : <img src={maskEn} alt='mask' />}
      </div>
      }
      <div id="id_loading"></div>
    </>
  )
}